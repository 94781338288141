<template>
  <div class="message">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
  export default {
    name: 'DisplayMessage',
    props: {
      msg: String
    }
  }
</script>

<style scoped>
  .message {
    font-size: 2em;
    margin: 20px;
  }
</style>
