<template>
  <DisplayMessage :msg="decodedMessage" />
</template>

<script>
import * as jose from 'jose'
import DisplayMessage from './components/DisplayMessage.vue'

export default {
  name: 'App',
  components: {
    DisplayMessage
  },

  data() {
    return {
      decodedMessage: 'Empty message',
      publicKey: '{"kty": "RSA","e": "AQAB","alg": "RS256","n": "22PAP611ed9VWNvHOG6Me7XT9MeaIoZvBPW_mrtua_rLwWirEau2d0mhqOXNGCsao-YoHHxaxiMsN_4zpc_ILzwVTIRGeOWy7xchfaGZmcu0KEDr57dtizxX0fMkpNHTF_EMxWJ8MQMBzuhDvnpj3RNqDpkGmjpgoVcz5WlmOsIsMX09Ojz6SELvegUissSaJLyoAgq7WZclF6-8UdhA2KGg7HaQZkbNFHt12TgxvtdPpcMjMDhiskeQufi7qmEFrlp_4zf0YkiRix1gyKzmIChKCoBcvyyx3eUBhUsLXBNDq7co7WIWIOwt2iEKGPU3nKsn4Fzz2ohSQxhfN6PVkw"}'
    }
  },
  async created() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    console.log(token);

    if (token) {
      try {
        const { payload, protectedHeader } = await jose.jwtVerify(token, this.publicKey);

        console.log('protectedHeader : ' + protectedHeader);
        console.log('payload : ' + payload);
        
        this.decodedMessage = payload.message || 'Erreur JWT';
      } catch (error) {
        console.error('Erreur lors du décodage du token :', error);
      }
    }
  }
}
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  }
</style>
